export const crawlerroutes = [
    {
        path: '/crawlerpage_1.1',
        name: 'crawlerpage_1.1',
        component: () => import('../views/PageContent/CrawlerContent/crawler1.1')
    },
    {
        path: '/crawlerpage_1.2',
        name: 'crawlerpage_1.2',
        component: () => import('../views/PageContent/CrawlerContent/crawler1.2')
    },
    {
        path: '/crawlerpage_1.3',
        name: 'crawlerpage_1.3',
        component: () => import('../views/PageContent/CrawlerContent/crawler1.3')
    },
    {
        path: '/crawlerpage_1.4',
        name: 'crawlerpage_1.4',
        component: () => import('../views/PageContent/CrawlerContent/crawler1.4')
    },
    {
        path: '/pythonpage_1.1',
        name: 'pythonpage_1.1',
        component: () => import('../views/PageContent/CrawlerContent/python1.1')
    },
    {
        path: '/robotpage_1.1',
        name: 'robotpage_1.1',
        component: () => import('../views/PageContent/CrawlerContent/robot1.1.vue')
    },
    {
        path: '/pythonpage_1.2',
        name: 'pythonpage_1.2',
        component: () => import('../views/PageContent/CrawlerContent/python1.2')
    },
    {
        path: '/ubuntupage_1.1',
        name: 'ubuntupage_1.1',
        component: () => import('../views/PageContent/CrawlerContent/ubuntu1.1')
    },
    {
        path: '/airsimpage_1.1',
        name: 'airsimpage_1.1',
        component: () => import('../views/PageContent/CrawlerContent/airsim1.1')
    },
    {
        path: '/airsimpage_1.2',
        name: 'airsimpage_1.2',
        component: () => import('../views/PageContent/CrawlerContent/airsim1.2')
    },
    {
        path: '/dino_1.1',
        name: 'dino_1.1',
        component: () => import('../views/PageContent/CrawlerContent/dino.vue')
    }
]